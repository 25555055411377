@import "../../node_modules/bootstrap/scss/functions";
@import "bootstrap/variables";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

#sidebar {
  &.navbar-dark {
    background-color: $dark;
    // Svg icon color
    .navbar-nav .nav-link:not(.active):not(:hover):not(.disabled) > svg {
      color: $gray-500;
    }
    // Link active style
    .navbar-nav .show > .nav-link,
    .navbar-nav .nav-link.active {
      background-color: rgba(#000, .3);
      @include border-radius($border-radius);
    }
    // Submenu link active style
    .navbar-nav .navbar-nav .show > .nav-link,
    .navbar-nav .navbar-nav .nav-link.active {
      background-color: transparent;
    }
    // Badge default color
    .badge:not([class^="bg-"]) {
      background-color: $primary;
    }
  }
}